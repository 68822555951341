@import "variables";
@import "mixins";

p {
    color: $primaryText_Color;
}

.page-content-dash {
    position: relative;
    // padding: 15px 0px;
}

.sb-card-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-dashboard {
    border-radius: 0px !important;
    border: none;
    width: 20%;
    padding: 0px 10px;
    background-color: transparent;
    // box-shadow: $card_BoxShadow;

    .card-body {
        &.first-card {
            width: 100%;
            padding: 5px !important;

            // background: $card_background !important;
            .card-left-img {
                background: transparent !important;
            }
        }

        // background-color: #efe7f6;
        background-color: $primaryVeryLight;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        // background: $card_part_background;
        min-height: 100px;
        max-height: 100px;
        border-radius: 10px;
        // box-shadow: $card_box_shadow;

        &:hover {
            border-radius: 0px !important;
            border: none;
            box-shadow: $card_shadow_hover;
        }
    }

}

.card-row {
    align-items: center;
    width: 100%;
}

.verticle-line {
    border-right: 1px solid $secondarySemiLight;
    height: 30px;
    padding-left: 5px;
}

.card-left-img {
    display: flex;
    padding: 6px;
    justify-content: center;
    // background: $card_background;
    border-radius: 4px;
    align-items: center;
}

.card-right-data {
    display: flex;
    align-items: center;
    padding: 0px;
    text-align: center;
    height: 100%;

    a {
        color: $primaryText_Color;
        text-decoration: none;

        &:hover {
            color: $primaryText_Color;
        }
    }
}

.card-head {
    font-size: 14px;
    font-weight: 600;
    // padding-right: 10px;

}

.act-num {
    font-size: $fz15;
}

.act-body {
    width: 100%;
    height: 100%;
}