@import "variables";
@import "mixins";

.sigin-page-body {
    width: 100%;
    height: 100vh;
    // display: flex;
    // align-items: center;
    // padding: 10px 0px;
}

.dflex-flow-row {
    display: flex;
    flex-direction: row;
    height: 100vh;

    .devider {
        display: flex;
        align-items: center;
        height: 100vh;
        padding: 5px 0px;

        .v1 {
            border-left: 2px solid lightgrey;
            height: 85% !important;
        }
    }
}

.signIn-container-left-div {
    padding: 10px 15px;
    position: relative;
    height: 100%;
}

.signIn-container-right-div {
    padding: 10px 15px;
    position: relative;
    height: 100%;
    background-color: $whiteColor;
    display: flex;
    flex-direction: column;
    justify-content: center;


    .metricinfo-logo {
        display: flex;
        justify-content: flex-end;

        .sb-trv-logo {
            width: 15%;
        }
    }
}

.signIn-container-middle-div {
    margin: auto;
}

.signIn-container-gif {
    width: 100%;
    // height: 92%;
    height: 87%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5% 0px auto 10%;
    padding: 5px 20px;

    img {
        width: 75%;
        object-fit: contain;
        margin: 10px auto;
        padding: 5px 10px;
    }

    .text-sliding-below-img {
        text-align: center;
        max-width: 50%;
        padding: 5px 10px;
    }
}

.signIn-container-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .signIn-logo {
        width: 30%;
    }
}

.signIn-container-right-div {
    .form-control {
        background: $whiteColor;
        border: 1px solid #e0e5eb;
        border-radius: 10px;
        color: #1c273c;
        font-size: 14px;
        font-weight: 500;
        outline: none;
        padding: 10px 35px;
        transition: 0.15s;
        vertical-align: middle;
        width: 100%;

        &:focus,
        :visited {
            background-color: $whiteColor;
        }
    }
}

input {

    &:-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
        -webkit-background-clip: text !important;
    }
}

.signin-header {
    &.login-part {
        margin: auto auto auto 0px;
        padding: 5px 0px;
        width: 70%;

        .signin-box {
            display: flex;
            flex-direction: column;

            .login-header-part {
                margin-bottom: 20px;
                margin-top: -10px;
            }
        }
    }

    &.otp-verification-part {
        margin: auto auto auto 0px;
        padding: 5px 0px;
        width: 70%;

        .otp-header-part {
            margin-bottom: 3rem;
            margin-top: -100px;
        }
    }

    &.signup-part {
        margin: auto auto auto 0px;
        padding: 5px 0px;
        width: 70%;

        .recaptcha-part {
            .recaptch-modal-kit {
                display: flex;
                justify-content: center;
                align-items: center;
                transform: scale(0.6);
            }
        }
    }

    &.forgotpass-part {
        margin: auto auto auto 0px;
        padding: 5px 0px;
        width: 70%;

        .forgotpass-header-part {
            margin-bottom: 3rem;
            margin-top: -20px;
        }

        .forgotpass-form-data {
            margin-bottom: 70px;
        }

    }

    h2 {
        color: #5842bc;
        font-weight: 500;
        letter-spacing: 0px;
        font-size: 1.8rem;
        margin-bottom: 5px;
    }

    h4 {
        font-weight: 500;
        margin-bottom: 15px;
        font-size: 1.2rem;
    }

    .form-title {
        font-weight: 500;
        margin-bottom: 15px;
    }
}

.signIn-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.mt20 {
        margin-top: 15px;
    }

    a {
        font-weight: 600;
        text-decoration: none;
        font-size: 14px;
    }
}


.icon-with-input {
    display: flex;
    align-items: center;
    position: relative;

    &.before {
        justify-content: flex-start;

        .fa {
            font-size: 15px;
            position: absolute;
            // top: 12px;
            left: 12px;
        }
    }

    .pass-hide-show {
        i {
            position: absolute;
            right: 0px;
            top: 2px;
        }
    }
}

.icon-with-select {
    position: relative;

    &.sbefore {
        .fa {
            font-size: 16px;
            position: absolute;
            top: 12px;
            left: 12px;
            z-index: 1;
        }
    }
}

.forgot-pass-n-get-otp {
    padding: 5px;

    .forget-pasword-div {
        a {
            font-weight: 500;
        }
    }

    .sign-in--using-otp-div {
        .otp-signin {
            font-size: 14px;
            font-weight: 500;
        }
    }
}

.signin-button {
    &.mb20 {
        margin-top: 25px;
    }

    &.mt10 {
        margin-top: 0px;
    }

    &.mt5 {
        margin-top: 1rem;
        margin-bottom: 7rem;
    }

    .btn-signin-button {
        width: 40%;
        height: auto;
        border: none;
        border-radius: 40px;
        color: $whiteColor;
        background-image: $buttonLinerGradient;

        &.signup {
            width: 60%;
        }

        &:focus,
        :hover {
            box-shadow: none;
            color: $whiteColor;
        }
    }
}

.form-group {
    &.mb20 {
        margin-bottom: 20px;
    }

    &.mt3 {
        margin-top: 1rem;
    }

    input::placeholder {
        color: $commonStatusColor;
    }

    .signIn-form-control {
        box-shadow: none !important;
    }
}

.signup-contry-codes-div {
    .cntry-code {
        width: 100%;

        &:focus:focus-within {
            box-shadow: none;
        }

        .css-13cymwt-control {
            line-height: 1;
            border: 1px solid #e0e5eb;
            border-radius: 10px;
            color: #1c273c;
            min-width: max-content;
            max-width: max-content;

            &:hover,
            :focus,
            :visited {
                border: 1px solid #e0e5eb;
                border-radius: 10px;
                padding: 5px;
            }
        }

        .css-t3ipsp-control {
            width: max-content;
        }
    }

    .source-creation {
        &:focus:focus-within {
            box-shadow: none;
        }

        // .css-1fdsijx-ValueContainer, 
        .react-select-list__value-container {
            padding: 2px 28px;
        }

    }

    .css-13cymwt-control {
        line-height: 1;
        border: 1px solid #e0e5eb;
        border-radius: 10px;
        padding: 5px;

        &:hover,
        :focus,
        :visited {
            border: 1px solid #e0e5eb;
            border-radius: 10px;
            padding: 5px;
        }
    }

    .css-1u9des2-indicatorSeparator {
        visibility: hidden !important;
    }

    .css-t3ipsp-control {
        border-color: #e0e5eb;
        box-shadow: none;
        border-radius: 10px;
        width: auto;
        padding: 5px;

        &:hover {
            border-color: #e0e5eb;
            box-shadow: none;
            border-radius: 10px;
            padding: 5px;
        }
    }

    .css-1xc3v61-indicatorContainer {
        padding: 1px !important;
    }

    .css-15lsz6c-indicatorContainer {
        padding: 1px !important;
    }

}

.back-to-signin {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        font-size: $fz14;

        &:hover {
            text-decoration: underline;
        }
    }
}

.contry-codes:focus:focus-within {
    box-shadow: none;
}


.contry-codes .css-1u9des2-indicatorSeparator {
    width: 0px !important;
}

.contry-codes .css-tj5bde-Svg {
    color: #808090 !important;
}


// OTP VERIFICATION PAGE START
// OtpVarification Page/

.otp-verification-container {
    display: flex;
    align-items: center;
    // justify-content: space-around;
    justify-content: space-between;
}

.otp-verification-container input {
    border-top: none;
    border-right: none;
    border-left: none;
    width: 40px;
    text-align: center;
    font-size: 20px;
}

.otp-verification-container input:focus {
    outline: none;
}

.sign-in-resend-otp-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;

    .sign-in-resend-otp {
        color: $secondaryColor;
        background-color: transparent;
        border: none;
        font-size: $fz12;
        cursor: pointer;
    }
}

.otp-signin {
    background-color: transparent !important;
    padding: 0;
    border: none;
    color: $primaryColor !important;

    &:focus {
        border: none;
        outline: none;
    }
}

.otp-varification-container-sec-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .otp-varification-btn {
        width: 150%;
        height: auto;
        border: none;
        border-radius: 40px;
        color: $whiteColor;
        background-image: $buttonLinerGradient;
    }
}

// OTP VERIFICATION PAGE END



@import "variables";
@import "mixins";

.sb-login-row {
    position: relative;

    .beta-tag-inside {
        padding: 6px 15px;
        background: $secondaryColor;
        position: absolute;
        top: 7px;
        left: -25px;
        transform: rotate(-45deg);
        width: 95px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9;
        border-radius: 100px 100px 0px 0px !important;

        h6 {
            color: $whiteColor;
        }
    }
}


.login-page-body {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: auto;

    .travelize-salesbee-logo {
        width: 400px;
        margin: 50px auto;
    }
}

@media only screen and (min-width: 1550px) {
    .login-page-body {
        height: 100vh;
    }

    .sb-form-section {
        width: 80%;
    }

    // .sb-login-bgimg {
    //     height: 550px;
    // }
}

.sb-form-section {
    width: 100%;
}

.sb-Acess-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0px;

    img {
        width: 55%;
    }
}

.sb-login-logo-div {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    padding-bottom: 16px;

    &.create-subscription {
        padding-bottom: 6px;
    }

    .sb-login-logo {
        width: 60%;

        &.metricinfo {
            width: 50%;
        }
    }
}

.sb-login-row {
    background: $whiteColor !important;
    box-shadow: 0px 0px 8px -1.9px $primarySemiLight;
    width: 70%;
    padding: 25px 30px;
    margin: auto;

    &.create-subscription {
        padding: 25px 30px;
    }
}

.travelize-log,
.devider {
    display: flex;
    justify-content: center;
    align-items: center;
}

.devider {
    padding: 5px 0px;

    .v1 {
        border-left: 2px solid $primarySemiLight;
        height: 120px;
    }
}


.sb-trv-logo {
    width: 65%;
}

.col-left {
    padding: 15px 25px;
    display: flex;
    align-items: center;
}

.col-right {
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 2% 1%;
}

.login-page {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    width: 100%;
    padding: 5px 10px;
    // height: 100%;
}

.sb-login-bgimg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .lgn-moving-img {
        height: 350px;
    }
}

.sb-signin-text {
    padding-top: 1%;
}

.sb-forgot-text {
    padding-top: 15%;
}

.login-head-text {
    font-size: $fz25;
    font-weight: $fwt600;
    color: $primaryText_Color;
    padding: 5px 0px;
    // margin-bottom: 30px;
}



.form-control {
    font-size: $fz13;
    background: $whiteColor !important;
    border: none;
    box-shadow: 0px 0px 6px -1.4px $primarySemiLight !important;
    padding: 6px 15px;
    color: $primaryText_Color;
    line-height: normal;
    height: auto;

    &.login-email,
    &.login-password {
        margin: 20px auto;
        border-radius: 4px !important;
        padding: 10px 15px;
        color: $primaryText_Color;
        background-color: $whiteColor !important;
        box-shadow: 0px 0px 6px -1.9px $primarySemiLight !important;
        // box-shadow: 0px 0px 3px 0.6px $primarySemiLight !important;
        border: none !important;
        position: relative;
    }

    &:focus {
        box-shadow: 0px 0px 6px -0.1px $primarySemiLight !important;
    }

    &.create-subscription-input {
        margin: 20px auto;
        border-radius: 4px !important;
        padding: 10px 15px;
        color: $primaryText_Color;
        background-color: $whiteColor !important;
        box-shadow: 0px 0px 6px -1.9px $primarySemiLight !important;
        border: none !important;
        position: relative;
    }
}

.contact-input {
    &.sub-inputs {
        .react-select-container-list {
            &.cntry-code {
                min-width: 70px !important;
                margin: 0px 6px 0px 0px !important;
            }

            .react-select-list__control {
                border-radius: 0.25rem 0 0 0.25rem !important;
            }
        }
    }
}

.create-subcription-otp {
    text-align: center;

    .form-field-div {
        margin: auto 20px;

        .add-OTP-inputs {
            border-radius: 5px;
            font-size: 13px;
            margin: 5px 16px;
        }
    }
}

.forgot-pass-text {
    text-decoration: none;
    font-size: $fz13;
    color: #0452c8;
    font-weight: 800;

    &:focus {
        text-decoration: none;
        box-shadow: none;
    }

    &:hover {
        text-decoration: underline;
    }
}

.login-signup-text {
    color: $primaryColor;

    &:hover {
        color: $primaryColor;
    }
}

.btn-login-button {
    background-color: $primaryColor;
    border-radius: 25px;
    font-size: $fz14;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 5px 10px;
    color: $whiteColor;
    margin-bottom: 0px;
    min-width: 100%;
    border: none;
    text-transform: uppercase;

    &.create-subscription {
        padding: 8px 15px;
    }

    &:hover {
        color: $whiteColor;
        background-color: $secondaryColor;
    }

    &:focus {
        background-color: $primaryColor;
    }

    &:focus {
        box-shadow: none;
    }
}

// }

.pass-hide-show {
    float: right;
    position: absolute;
    top: 18%;
    right: 5%;
    font-size: 18px;
    color: $primaryText_Color;
    cursor: pointer;
}

.password-input {
    display: block;
    position: relative;
}


.login-msg {
    font-size: $fz14;

    &.success {
        color: $statusGreen
    }

    &.false {
        color: $dangerColor;
    }
}


.subscription-page-body {
    text-align: center;
    align-items: center;
    background: $mainBody_backgroundImg left top repeat $nav_back_Graycolor;
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: auto;

    .salesbeebasiclogo {
        width: 20%;
        margin: 30px auto;
    }
}

.contact-input {
    display: flex;
}

.sub-inputs {
    margin: 15px auto !important;

    .form-control {
        border-radius: 0 0.25rem 0.25rem 0 !important;
        // border-top-left-radius: 0 !important;
        // border-top-right-radius: 0.25rem;
        // border-bottom-left-radius: 0 !important;
        // border-bottom-right-radius: 0.25rem;
        padding: 10px 15px;
    }

    .form-select {
        padding: 10px 15px;
        display: block;
        border: none !important;
        box-shadow: 0px 0px 6px -1.4px $primarySemiLight !important;
        padding: 0.375rem 2.25rem 0.375rem 0.75rem;
        -moz-padding-start: calc(0.75rem - 3px);
        font-size: $fz14;
        font-weight: 400;
        line-height: 1.5;
        color: $primaryText_Color;
        background-color: $whiteColor !important;
        border: none;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0 !important;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &.cntry-code {
            width: 35% !important;

            &:focus {
                border: none !important;
                box-shadow: 0px 0px 6px -0.2px $primarySemiLight !important;
            }
        }
    }
}

.btn-submit-button {
    background-color: $primaryColor;
    border: none;
    border-radius: 5px;
    font-size: $fz14;
    padding: 10px 10px;
    color: $whiteColor;
    margin: 10px auto;
    width: 100%;

    &:hover {
        color: $whiteColor;
        background-color: $primaryColor;
        box-shadow: none;
    }
}

.subscription-res-message {
    .alert {
        padding: 0.5rem 0.5rem;
        font-size: $fz14;
        font-weight: $fwt400;
        text-transform: capitalize;
        border: none;
        margin-bottom: 0px;
        opacity: 1;
        animation-name: visibility;
        animation-duration: 10s;
    }

    @keyframes visibility {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }
}

@media (max-width: 576px) {

    .travelize-salesbee-logo {
        width: 60% !important;
        margin: 30px auto;
    }

    .subscription-box {
        width: auto !important;
        display: block;
        align-items: center;
    }

    .salesbeebasiclogo {
        width: 60% !important;
        margin: 30px auto;
    }

}

// css for captcha
.custom-recaptcha {
    /* Add your custom styles here */
    // For example:
    width: 300px;
    margin: 0 auto;

    // iframe {
    //     // height: 60px !important;
    //     // transform: scale(0.9 0) !important;
    // }

    .rc-anchor-normal {
        height: 55px !important;
    }
}

// css for captcha